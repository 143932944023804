const naPhone = /^\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})(\s*x[0-9]+)?$/;
const regex = {
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
    },
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/,
        xx: /^\d{5}(-\d{4})?$/,
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    email: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/,
    nameAndAddress: /^[A-Za-z0-9&#/& -]+$/,
    personalizationMsg: /^[A-Za-z0-9&#/&!, .-]+$/,

};
const requiredLengths = {
    password: {
        min: 6,
        max: 255,
    },
};

function checkBilling() {
    /* Loop through form fields to see if an error exists */
    $('#dwfrm_billing input').each((index, element) => {
        if ($(element).hasClass('error') || $('#dwfrm_billing_billingAddress_addressFields_states_state').hasClass('error')) {
            $('#dwfrm_billing button[name="dwfrm_billing_createAccount"]').attr('disabled', 'disabled');
            $('#dwfrm_billing button[name="dwfrm_billingaddress_apply"]').attr('disabled', 'disabled');
            $('#dwfrm_billing button[name="dwfrm_billing_addAddress"]').attr('disabled', 'disabled');
            return false; /* Found Error, stop loop, no need to check more */
        }
        $('#dwfrm_billing button[name="dwfrm_billing_createAccount"]').removeAttr('disabled');
        $('#dwfrm_billing button[name="dwfrm_billingaddress_apply"]').removeAttr('disabled');
        $('#dwfrm_billing button[name="dwfrm_billing_addAddress"]').removeAttr('disabled');
        return true;
    });
}

function checkShipping() {
    /* Loop through form fields to see if an error exists */
    $('#EditAddressForm input').each((index, element) => {
        if ($(element).hasClass('error') || $('#dwfrm_shippingaddress_states_state').hasClass('error')) {
            $('#dwfrm_singleshipping_shippingAddress button[name="dwfrm_singleshipping_shippingAddress_save"]').attr('disabled', 'disabled');
            $('#dwfrm_singleshipping_shippingAddress button[name="dwfrm_shippingaddress_apply"]').attr('disabled', 'disabled');
            return false; /* Found Error, stop loop, no need to check more */
        }
        $('#dwfrm_singleshipping_shippingAddress button[name="dwfrm_singleshipping_shippingAddress_save"]').removeAttr('disabled');
        $('#dwfrm_singleshipping_shippingAddress button[name="dwfrm_shippingaddress_apply"]').removeAttr('disabled');
        return true;
    });
}

function checkCreateAccount() {
    /* Loop through form fields to see if an error exists */
    $('#RegistrationForm input').each((index, element) => {
        if ($(element).hasClass('error') || $('#dwfrm_profile_address_states_state').hasClass('error')) {
            $('button[name="dwfrm_profile_confirm"]').attr('disabled', 'disabled');
            return false; /* Found Error, stop loop, no need to check more */
        }
        $('button[name="dwfrm_profile_confirm"]').removeAttr('disabled');
        return true;
    });
}
function checkAddAddress() {
    /* Loop through form fields to see if an error exists */
    $('#edit-address-form input').each((index, element) => {
        if ($(element).hasClass('error') || $('#dwfrm_profile_address_states_state').hasClass('error')) {
            $('button[name="dwfrm_profile_address_create"]').attr('disabled', 'disabled');
            return false; /* Found Error, stop loop, no need to check more */
        }
        $('button[name="dwfrm_profile_address_create"]').removeAttr('disabled');
        return true;
    });
}
function checkCatalogRequest() {
    /* Loop through form fields to see if an error exists */
    $('#CatalogRequestForm input').each((index, element) => {
        if ($(element).hasClass('error') || $('#dwfrm_catalogrequest_addressFields_states_state').hasClass('error')) {
            $('button[name="dwfrm_catalogrequest_send"]').attr('disabled', 'disabled');
            return false; /* Found Error, stop loop, no need to check more */
        }
        $('button[name="dwfrm_catalogrequest_send"]').removeAttr('disabled');
        return true;
    });
}

function checkMultiship() {
    /* Loop through form fields to see if an error exists */
    $('#EditAddressForm input').each((index, element) => {
        if ($(element).hasClass('error') || $('#dwfrm_multishipping_editAddress_addressFields_states_state').hasClass('error')) {
            $('#dwfrm_multishipping_addressSelection button[name="dwfrm_multishipping_editAddress_save"]').attr('disabled', 'disabled');
            $('#dwfrm_multishipping_addressSelection button[name="dwfrm_multishipping_addressSelection_save"]').attr('disabled', 'disabled');
            return false; /* Found Error, stop loop, no need to check more */
        }
        $('#dwfrm_multishipping_addressSelection button[name="dwfrm_multishipping_editAddress_save"]').removeAttr('disabled');
        $('#dwfrm_multishipping_addressSelection button[name="dwfrm_multishipping_addressSelection_save"]').removeAttr('disabled');
        return true;
    });
}

// global form validator settings
const settings = {
    errorClass: 'error',
    errorElement: 'span',
    errorPlacement($error, $element) {
        const $selectStyle = $element.parent('.select-style');
        const $label = $element.next('label');

        if ($selectStyle.length > 0) {
            $selectStyle.after($error);
        } else if ($element.attr('type') === 'checkbox' || $element.attr('type') === 'radio') {
            if ($label.length > 0) {
                $label.after($error);
            } else {
                $element.after($error);
            }
        } else {
            $element.after($error);
        }
    },
    ignore: '.suppress, :hidden',
    onkeyup: false,
    onfocusout(element) {
        if (!this.checkable(element) && !$(element).hasClass('hasDatepicker')) {
            this.element(element);
        }
        checkBilling();
        checkShipping();
        checkCreateAccount();
        checkAddAddress();
        checkCatalogRequest();
        checkMultiship();
    },
    highlight(element, errorClass) {
        $(element).addClass(errorClass);
        $(element.form).find(`label[for=${element.id}]`).addClass(errorClass);
    },
    unhighlight(element, errorClass) {
        $(element).removeClass(errorClass);
        $(element.form).find(`label[for=${element.id}]`).removeClass(errorClass);
    },
};

/**
 * @function
 * @description Validates the given email address
 * @param {String} value The email field which will be validated
 * @param {String} el The input field
 */
function validateEmail(value, el) {
    let isValid = true;
    if ($(el).hasClass('required') || value.length > 0) {
        isValid = regex.email.test($.trim(value));
    }
    return isValid;
}

/**
 * @function
 * @description Validates the given email address
 * @param {String} value The email field which will be validated
 * @param {String} el The input field
 */
function validateConfirmEmail(value, el) {
    let isValid = regex.email.test($.trim(value));
    const buttons = $(el).closest('form').find('*[type="submit"]');
    if (isValid) {
        const sourceElement = $('input[name*="dwfrm_profile_customer_emailconfirm"]');
        const sourceValue = sourceElement.val();
        isValid = value.trim().toLowerCase() === sourceValue.trim().toLowerCase();
    }

    if (isValid) {
        buttons.removeAttr('disabled');
    } else {
        buttons.attr('disabled', 'disabled');
    }

    return isValid;
}

/**
 * @function
 * @description Validates the given email address
 * @param {String} value The email field which will be validated
 * @param {String} el The input field
 */
function validateNewsletterEmail(value, el) {
    let isValid = true;
    if ($(el).length > 0) {
        isValid = regex.email.test($.trim(value));
    }
    return isValid;
}

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
function validatePhone(value, el) {
    const country = $(el).closest('form').find('.country');
    let countryValue = 'us';

    if (country.size() !== 0 && country.val()) {
        countryValue = country.val().toLowerCase();
    }

    return this.optional(el) || regex.phone[countryValue].test($.trim(value));
}

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
function validateOwner(value) {
    return regex.notCC.test($.trim(value));
}

/**
 * @function
 * @description Validates a given postal code against the countries postal code regex
 * @param {String} value The postal code which will be validated
 * @param {String} el The input field
 */
function validatePostalCode(value, el) {
    const postalCode = $(el).closest('form').find('.postal');
    const country = $(el).closest('form').find('.country');
    let rgx;
    const rgxXX = 'xx';
    if (country.length > 0 && !regex.postal[country.val().toLowerCase()]) {
        rgx = regex.postal[rgxXX];
    } else if (country.length > 0) {
        rgx = regex.postal[country.val().toLowerCase()];
    } else {
        rgx = regex.postal[rgxXX];
    }
    const isValid = rgx.test($.trim(value));

    if (isValid && (postalCode.length === 0 || postalCode.val().length === 0)) {
        return true;
    }

    return isValid;
}

function validateNameAndAddress(value, el) {
    let isValid = regex.nameAndAddress.test($.trim(value));

    if (!$(el).hasClass('required') && value.length === 0) {
        isValid = true;
    }

    return isValid;
}

function validatePersonalizationMsg(value, el) {
    let isValid = regex.personalizationMsg.test($.trim(value));

    if (!$(el).hasClass('required') && value.length === 0) {
        isValid = true;
    }

    return isValid;
}

/**
 * @function
 * @description Validates personalization input based on punctuation data attribute
 * example: "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
function validatePersonalization(value, el) {
    let isValid = true;
    let punctuation = $(el).data('punctuation');
    // Data attributes will always make every attempt to convert to a JS value (booleans, numbers, objects, arrays, and null)
    // in order to use the methods below it needs to be a string so we always attempt to convert back to a string in case it has been altered.
    punctuation = punctuation.toString();
    if (punctuation != null && punctuation !== '' && value) {
        punctuation = punctuation.replace(/[^a-z\d\s]/gi, (specialChar) => `\\${specialChar}`);
        const rgx = new RegExp(`^[${punctuation}]+$`, 'gi');
        isValid = value.match(rgx);

        if (isValid) {
            $(el).removeAttr('invalidChars', '');
        } else {
            // Regex to find invalid characters
            const invalidCharsRgx = new RegExp(`[^${punctuation}]`, 'gi');
            // Regex to match invalid characters, to return array of chars
            const invalidChars = value.match(invalidCharsRgx);
            // Remove duplicates
            const uniqueInvalidChars = [...new Set(invalidChars)];

            if (uniqueInvalidChars.length) {
                $(el).attr('invalidChars', uniqueInvalidChars.join(','));
            }
        }
    }
    if (isValid && value && $(el).data('fieldname') === 'date') {
        isValid = value.match(/^(0?[1-9]|1[012]).(0?[1-9]|[12][0-9]|3[01]).(?:[0-9]{2})?[0-9]{2}$/);
    }

    return isValid;
}

/**
 * @function
 * @description Validates length range
 * @param {String} value The owner field which will be validated
 */
function validatePasswordLengthRange(value, el) {
    let isValid = true;
    if ($(el).hasClass('required') && el.getAttribute('name').indexOf('ssn') < 0 && (value.length < requiredLengths.password.min || value.length > requiredLengths.password.max)) {
        isValid = false;
    }
    return isValid;
}

function validateGiftCertAmount(value, el) {
    return this.optional(el) || (!Number.isNaN(Number(value)) && parseFloat(value) >= 5 && parseFloat(value) <= 5000);
}

function validatePositiveNumber(value) {
    return $.trim(value).length === 0 || (!Number.isNaN(Number(value)) && Number(value) >= 0);
}

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', validateGiftCertAmount, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', validatePositiveNumber, ''); // '' should be replaced with error message if needed

/**
 * Add Email validation method to jQuery validation plugin.
 * Text fields must have 'email' css class to be validated
 */
$.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);
/**
 * Add Email Confirm validation method to jQuery validation plugin.
 * Text fields must have 'emailconfirm' css class to be validated
 */
$.validator.addMethod('emailconfirm', validateConfirmEmail, Resources.VALIDATE_EQUAL_EMAIL);

/**
 * Newsletter signup validation method to jQuery validation plugin.
 * Text field when empty renders appropriate message as validation.
 */
$.validator.addMethod('newsletterSignup', validateNewsletterEmail, Resources.VALIDATE_NEWSLETTER_SIGNUP);

/**
 * Add Personalization validation method to jQuery validation plugin.
 * Text fields must have 'personalization-input' css class to be validated
 */
$.validator.addMethod('personalization-input', validatePersonalization, (params, element) => {
    const invalidChars = $(element).attr('invalidChars') || '';
    let errorMessage = Resources.VALIDATE_PERSONALIZATION_NOTALLOWED;
    if (!invalidChars) {
        errorMessage = errorMessage.replace(':', '');
    }
    return `${errorMessage} ${invalidChars}`;
});

/**
 * Add postal validation method to jQuery validation plugin.
 * Text fields must have 'postal' css class to be validated as a postal code
 */
$.validator.addMethod('postal', validatePostalCode, Resources.INVALID_POSTALCODE);

/**
 * Add Name and Address validation method to jQuery validation plugin.
 * Text fields must have the following classes to be validated as Name And Address
 * firstName, lastName, addressOne, addressTwo, or city
 */
$.validator.addMethod('firstName', validateNameAndAddress, `${Resources.VALIDATE_PERSONALIZATION} Letters, Numbers, &amp; - # &#47;`);
$.validator.addMethod('lastName', validateNameAndAddress, `${Resources.VALIDATE_PERSONALIZATION} Letters, Numbers, &amp; - # &#47;`);
$.validator.addMethod('addressOne', validateNameAndAddress, `${Resources.VALIDATE_PERSONALIZATION} Letters, Numbers, &amp; - # &#47;`);
$.validator.addMethod('addressTwo', validateNameAndAddress, `${Resources.VALIDATE_PERSONALIZATION} Letters, Numbers, &amp; - # &#47;`);
$.validator.addMethod('city', validateNameAndAddress, `${Resources.VALIDATE_PERSONALIZATION} Letters, Numbers, &amp; - # &#47;`);
$.validator.addMethod('company', validateNameAndAddress, `${Resources.VALIDATE_PERSONALIZATION} Letters, Numbers, &amp; - # &#47;`);
$.validator.addMethod('message', validatePersonalizationMsg, `${Resources.VALIDATE_PERSONALIZATION} Letters, Numbers, &amp; . - # &#47;`);

/**
 * Add password length validation method to jQuery validation plugin.
 * Text fields must have 'password' css class to be validated as a postal code
 */
$.validator.addMethod('password', validatePasswordLengthRange, $.validator.messages.rangelength(requiredLengths.password.min, requiredLengths.password.max));

$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN),
    newsletterSignUp: Resources.VALIDATE_NEWSLETTER_SIGNUP,
});

module.exports = {
    regex,
    settings,
    init() {
        $('form:not(.suppress)').each((index, element) => {
            $(element).validate(settings);
        });
    },
    initForm(f) {
        $(f).validate(settings);
    },
};
