exports.init = function init() {
    $('.country-selector .current-country').on('click', (event) => {
        $('.country-selector .selector').toggleClass('active');
        $(event.currentTarget).toggleClass('selector-active');
    });
    // set currency first before reload
    $('.country-selector .selector .locale').on('click', (event) => {
        event.preventDefault();
        const url = event.currentTarget.href;
        const currency = event.currentTarget.getAttribute('data-currency');
        $.ajax({
            dataType: 'json',
            url: Urls.setSessionCurrency,
            data: {
                format: 'ajax',
                currencyMnemonic: currency,
            },
        }).done((response) => {
            if (!response.success) {
                throw new Error('Unable to set currency');
            }
            window.location.href = url;
        });
    });
};
