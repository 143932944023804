const util = require('./util');

const page = {
    title: '',
    type: '',
    params: util.getQueryStringParams(window.location.search.substr(1)),
    redirect(newURL) {
        setTimeout(() => {
            window.location.href = newURL;
        }, 0);
    },
    refresh() {
        setTimeout(() => {
            window.location.assign(window.location.href);
        }, 500);
    },
};

module.exports = page;
