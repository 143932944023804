// jQuery extensions

module.exports = function jqueryExt() {
    // params
    // toggleClass - required
    // triggerSelector - optional. the selector for the element that triggers the event handler. defaults to the child elements of the list.
    // eventName - optional. defaults to 'click'
    $.fn.toggledList = function toggledList(options) {
        if (!options.toggleClass) { return this; }
        const list = this;
        return list.on(options.eventName || 'click', options.triggerSelector || list.children(), (e) => {
            e.preventDefault();
            const classTarget = options.triggerSelector ? $(e.currentTarget).parent() : $(e.currentTarget);
            classTarget.toggleClass(options.toggleClass);
            // execute callback if exists
            if (options.callback) { options.callback(); }
        });
    };

    $.fn.syncHeight = function syncHeight() {
        const arr = $.makeArray(this);
        arr.sort((a, b) => $(a).height() - $(b).height());
        return this.height($(arr[arr.length - 1]).height());
    };
};
