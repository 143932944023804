/**
 * @private
 * @function
 * @description Binds event to the place holder (.blur)
 */
function init() {
    $('#q').focus((event) => {
        const input = $(event.currentTarget);
        if (input.val() === input.attr('placeholder')) {
            input.val('');
        }
    }).blur((event) => {
        const input = $(event.currentTarget);
        if (input.val() === '' || input.val() === input.attr('placeholder')) {
            input.val(input.attr('placeholder'));
        }
    }).blur();
}

module.exports = {
    init,
};
