const util = require('./util');

/**
 * copied from https://github.com/darkskyapp/string-hash
 */
function hashFn(str) {
    let hash = 5381;
    let i = str.length;

    while (i) {
        // eslint-disable-next-line no-bitwise
        hash = (hash * 33) ^ str.charCodeAt(i -= 1);
    }
    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
    * integers. Since we want the results to be always positive, convert the
    * signed int to an unsigned by doing an unsigned bitshift. */
    // eslint-disable-next-line no-bitwise
    return hash >>> 0;
}

/**
 * Create rating based on hash ranging from 2-5
 * @param pid
 */
function getRating(pid) {
    return (hashFn(pid.toString()) % 30) / 10 + 2;
}

module.exports = {
    init() {
        $('.product-review').each((index, review) => {
            const pid = $(review).data('pid');
            if (!pid) {
                return;
            }
            // rating range from 2 - 5
            const rating = getRating(pid);
            const baseRating = Math.floor(rating);
            let starsCount = 0;
            for (let i = 0; i < baseRating; i += 1) {
                $('.rating', review).append(util.svg('ratings-full'));
                starsCount += 1;
            }
            // give half star for anything in between
            if (rating > baseRating) {
                $('.rating', review).append(util.svg('ratings-half'));
                starsCount += 1;
            }
            if (starsCount < 5) {
                for (let j = 0; j < 5 - starsCount; j += 1) {
                    $('.rating', review).append(util.svg('ratings-empty'));
                }
            }
        });
    },
};
