'use strict';
var util = require('org/util');
var page = require('org/page');
var validator = require('org/validator');
var cache = {};
var marker = false;

function initCache () {
    var currentForm = '',
        saveButton = '',
        source = '',
        refresh = false,
        url = Urls.qasVerifyAddress,
        secondSaveButton = '';

    if ($('[name="dwfrm_multishipping_editAddress_save"]').length > 0) {
        currentForm = $('#EditAddressForm');
        source = 'dwfrm_multishipping_editAddress_addressFields';
        saveButton = 'dwfrm_multishipping_editAddress_save';
    } else if ($('form.checkout-billing.address').length > 0) {
        currentForm = $('form.checkout-billing.address');
        source = 'dwfrm_billing_billingAddress_addressFields';
        saveButton = $('[name="dwfrm_billing_createAccount"]').length > 0 ? 'dwfrm_billing_createAccount' : 'dwfrm_billing_addAddress';
        secondSaveButton = 'dwfrm_billingaddress_apply';
    } else if ($('#EditAddressForm').length > 0) {
        currentForm = $('#EditAddressForm');
        source = 'dwfrm_shippingaddress';
        saveButton = 'dwfrm_shippingaddress_apply';
    } else if ($('#edit-address-form').length > 0) {
        currentForm = $('#edit-address-form');
        source = 'dwfrm_profile_address';
        saveButton = currentForm.find('.apply-button').attr('name');
        refresh = true;
    } else if ($('#CatalogRequestForm').length > 0) {
        currentForm = $('#CatalogRequestForm');
        source = 'dwfrm_catalogrequest_addressFields';
        saveButton = 'dwfrm_catalogrequest_send';
    } else if ($('.checkout-shipping.address').length > 0) {
        currentForm = $('.checkout-shipping.address');
        source = 'dwfrm_singleshipping_shippingAddress_addressFields';
        saveButton = 'dwfrm_singleshipping_shippingAddress_save';
    } else if ($('form#RegistrationForm').length > 0) {
        currentForm = $('form#RegistrationForm');
        source = 'dwfrm_profile_address';
        saveButton = 'dwfrm_profile_confirm';
    }

    cache = {
        dialog: require('org/dialog'),
        $form: currentForm, //change to - check the form
        $source: source,
        $save: saveButton,
        $save2: secondSaveButton,
        $url: url,
        refresh : refresh
    }
}

function initEvents () {
    if (cache.$form.length > 0) {
        cache.$form.on('click', 'button[name="' + cache.$save + '"]', qas.eventHandlers.validate);
        if (cache.$save2.length > 0) {
            cache.$form.on('click', 'button[name="' + cache.$save2 + '"]', qas.eventHandlers.validate);
        }
    }
}

function applyEditAddress ($form) {
    if (!$form.valid()) {
        return false;
    }

    $.ajax({
        url: util.appendParamToURL($form.attr('action'), 'format', 'ajax'),
        data: $form.serialize() + '&' + $form.find('.apply-button').attr('name') + '=x',
        type: 'POST'
    }).done(function () {
        page.refresh();
    });
}

function cacheFormSubmit () {
    if (cache.$source == 'dwfrm_profile_address' && cache.refresh) {
        applyEditAddress(cache.$form);
        return;
    }

    if (cache.$source == 'dwfrm_shippingaddress') {
        cache.$form.trigger('submit').trigger('submit');
        setTimeout(function () {
            initCache();
        }, 100);
        return;
    }

    if (cache.$save == 'dwfrm_billing_save' && !marker) {
        validation(cache.$form.serialize() + '&source=' + cache.$source, Urls.qasVerifyEmail);
        return false;
    }

    cache.$form.append('<input type="hidden" name="' + cache.$save + '"/>');
    cache.$form.submit();
}

function formAddrUpdate (addr) {
    var state = addr.state || '';
    cache.$form.find('input[name$="_address1"]').val(addr.add1);
    cache.$form.find('input[name$="_address2"]').val(addr.add2);
    cache.$form.find('select[id$="_state"]').val(state.toUpperCase());
    cache.$form.find('input[name$="_city"]').val(addr.city);
    cache.$form.find('input[name$="_postal"]').val(addr.zip);
}

function validation (data, url) {
    $.ajax({
        type: 'POST',
        url: url,
        data: data,
        dataType: 'html'
    }).done(function (content, textStatus, request) {
        var response = request.getResponseHeader('Content-Type');
        if (/json/.test(response)) {
            response = jQuery.parseJSON(content);
            if (response.action === 'addrVerified') {
                formAddrUpdate(response.address);
                cacheFormSubmit();
            }
            if (response.action === 'verified') {
                if (cache.$save == 'dwfrm_billing_save' && url == Urls.qasVerifyEmail) {
                    marker = true;
                }
                cacheFormSubmit();
            }
        } else {
            cache.dialog.create({
                target: $('#ModalViewDialog'),
                options: {
                    width: '768',
                    height: 'auto',
                    dialogClass: 'qas-dialog',
                    open: function() {
                        $('.addForm').on('click', 'button', qas.eventHandlers.submitAddr);
                        $('.qas_email_phone_form').on('click', 'button', qas.eventHandlers.submitEmail);
                        $('.newAddressText').on('click', function (e) {
                            e.preventDefault();
                            $('.newAddress').slideToggle('slow');
                            $('.oldAddress').slideToggle('slow');
                        });
                        $('.showHide').on('click', function (e) {
                            e.preventDefault();
                            $('.picklist').slideToggle('slow');
                        });
                        validator.init();
                    }
                }
            });
            cache.dialog.openWithContent({
                content: content
            });
        }
    });
}

var qas = {

    init: function () {
        initCache();
        initEvents();
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'ModalViewDialog').appendTo(document.body);
        }
    },

    eventHandlers: {

        submitAddr: function (e) {
            var form = $(this).parents('form');
            if (!form.valid()) {
                e.preventDefault();
                return;
            }
            form.append('<input type="hidden" name="' + $(this).attr('name') + '"/>');
            var data = form.serialize();
            $.ajax({
                type: 'POST',
                url: Urls.qasUpdateAddress,
                data: data,
                dataType: 'html'
            }).done(function (response) {
                response = jQuery.parseJSON(response);
                if (response.action == 'reValidate') {
                    cache.dialog.close();
                    qas.eventHandlers.validate('reValidate');
                } else {
                    if (response.action != 'useOrig') {
                        formAddrUpdate(response.address);
                    }
                    cacheFormSubmit();
                }
            });
            cache.dialog.close();
            return false;
        },

        submitEmail: function () {
            var form = $(this).parents('form');
            var email = form.find('input[name="Email"]').val();
            var useOrig = form.find('input[name="Override"]').val();
            if (useOrig != 'true') {
                cache.$form.find('input[name$="_email"]').val(email);
                cache.$form.find('input[name$="_emailconfirm"]').val(email);
                if (cache.$save == 'dwfrm_billing_save'){
                    cache.$form.find('input[name$="_emailAddress"]').val(email);
                }
            } else {
                email = cache.$form.find('input[name$="_email"]').val();
                cache.$form.find('input[name$="_emailconfirm"]').val(email);
            }
            if (cache.$save == 'dwfrm_billing_save') {
                marker = true;
            }
            cacheFormSubmit();
            cache.dialog.close();
            return false;
        },

        validate: function (action) {
            /*
             * On the billing page for registered customers, the customer could click 'Continue' or 'Apply',
             * and the expected outcome is slightly different.  Make sure that the correct button name is
             * passed here based on the customer's actions.
             */
            if (typeof action !== 'string') {
                let $tmp = $(action.currentTarget);
                if ($tmp.length > 0 && $tmp.attr('name').length > 0) {
                    cache.$save = $tmp.attr('name');
                    if (cache.$save === 'dwfrm_billingaddress_apply') {
                        cache.$form = $('#EditAddressForm');
                    }
                }
            }

            if (cache.$form.validate().form()) {
                var data = cache.$form.serialize() + '&source=' + cache.$source;
                if (typeof action === 'string') {
                    data += '&dwfrm_addForm_action=' + action;
                }
                validation(data, cache.$url);
                return false;
            }
        }
    },

    show: function () {
        if (!this.exists()) {
            this.init();
        }
    },

    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports = qas;
