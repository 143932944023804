'use strict';

/* global utag, utag_data, $, exports */

function addToCart(productObject, quantity) {
    utag.link({
        'tealium_event': 'cart_add',
        'product_id': [productObject.id],
        'product_name': [productObject.name],
        'product_quantity': [quantity],
        'product_price': [productObject.price],
        'category_id': utag_data.category_id
    });
}

var events = {
    product: function () {
        $('#main').on('click', 'button.add-to-cart', function () {
            if ($(this).attr('data-tealium')) {
                addToCart($.parseJSON($(this).attr('data-tealium')), $(this).closest('form').find('#Quantity, [name="Quantity"]').val());
            }
        });
    }
};

exports.init = function (nameSpace) {
    if (nameSpace && events[nameSpace]) {
        events[nameSpace]();
    }
};
