const progress = require('./progress');
const util = require('./util');

const currentRequests = [];

/**
 * @function
 * @description Ajax request to get json response
 * @param {Boolean} async  Asynchronous or not
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 */
function getJson(options) {
    const url = util.toAbsoluteUrl(options.url);
    // return if no url exists or url matches a current request
    if (!url || currentRequests[url]) {
        return;
    }

    currentRequests[url] = true;

    $.ajax({
        url,
        dataType: 'json',
        async: (typeof options.async === 'undefined' || options.async === null) ? true : options.async,
        data: options.data || {},
    }).done((response) => {
        if (options.callback) {
            options.callback(response);
        }
    }).fail((xhr, textStatus) => {
        if (textStatus === 'parsererror') {
            window.alert(Resources.BAD_RESPONSE);
        }
        if (options.callback) {
            options.callback(null);
        }
    }).always(() => {
        if (currentRequests[url]) {
            delete currentRequests[url];
        }
    });
}

/**
 * @function
 * @description ajax request to load html response in a given container
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 * @param {Object} target Selector or element that will receive content
 */
function load(options) {
    const url = util.toAbsoluteUrl(options.url);
    // return if no url exists or url matches a current request
    if (!url || currentRequests[url]) {
        return;
    }

    currentRequests[url] = true;

    $.ajax({
        dataType: 'html',
        url: util.appendParamToURL(url, 'format', 'ajax'),
        data: options.data,
        xhrFields: {
            withCredentials: true,
        },
    }).done((response) => {
        if (options.target) {
            $(options.target).empty().html(response);
        }
        if (options.callback) {
            options.callback(response);
        }
    }).fail((xhr, textStatus) => {
        if (textStatus === 'parsererror') {
            window.alert(Resources.BAD_RESPONSE);
        }
        options.callback(null, textStatus);
    }).always(() => {
        progress.hide();
        if (currentRequests[url]) {
            delete currentRequests[url];
        }
    });
}

module.exports = {
    getJson,
    load,
};
