/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
module.exports = {
    init() {
        $(document).tooltip({
            items: '.tooltip',
            track: true,
            content() {
                return $(this).find('.tooltip-content').html();
            },
        });

        $('.share-link').on('click', (event) => {
            event.preventDefault();
            const target = $(event.currentTarget).data('target');
            if (!target) {
                return;
            }
            $(target).toggleClass('active');
        });
    },
};
