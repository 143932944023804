const dialog = require('./dialog');
const util = require('./util');

const {SessionAttributes} = window;

/**
 * @function captcha    Used to display/control the scrim containing the simulated captcha code
 * */
module.exports = () => {
    /**
     * if the session.privacy.ratelimited element is present then show the notification
     * NOTE: You will probably want to replace this with a call to an actual CAPTCHA system to replace the simple one here
     */
    if (SessionAttributes.SHOW_CAPTCHA) {
        dialog.open({
            url: Urls.loginUnsuccessful,
            options: {
                closeOnEscape: false,
                dialogClass: 'no-close',
                buttons: [{
                    text: Resources.OK,
                    click() {
                        const url = util.appendParamsToUrl(Urls.rateLimiterReset, {format: 'ajax'});
                        $.ajax({
                            url,
                        });
                        $(this).dialog('close');
                    },
                }],
            },
        });
    }
};
