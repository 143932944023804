const AVS_CODES = ['M', 'X', 'Y', 'Z', '', null];
const ERROR_CODES = ['5000', '5001', '5021', '5022', '5100'];
const TRY_AGAIN = Resources.LIGHTBOX_TRYAGAIN + '<br /><br />' + Resources.LIGHTBOX_CONTACTYOURPROVIDER;
const LIGHTBOX_ERROR = Resources.LIGHTBOX_ERROR.replace('PHONE#', SitePreferences.CUSTOMER_SERVICE_PHONE);

let brandPrimary = null;
let dpCardAdded = false;

function updateStatus (msg) {
    $('.lightbox-status').html(msg);
}

function openLightbox (tokenUrl, callbacks, postData) {
    if (!postData) {
        postData = null;
    }

    $.ajax({
        type: 'POST',
        data: postData,
        async: false,
        url: tokenUrl,
        success: function (data) {
          
            if (data && data.location) {
                window.location.href = data.location;
            } else if (data && data.sessionToken) {
                const sessionTokens = data.sessionToken;

                transationToken = data.sessionToken;

                const paymentFields = {
                    ssl_txn_auth_token: sessionTokens,
                };

                if (typeof callbacks !== "object" || callbacks === null) {
                    callbacks = {};
                }

                if (typeof callbacks.afterAll !== "function") {
                    callbacks.afterAll = function () {};
                }

                const options = {};
                if (brandPrimary) {
                    options.cssOverride = `.md-button.md-raised {background-color: ${brandPrimary} !important; color: white !important;}`;
                }

                PayWithConverge.open(
                    paymentFields,
                    {
                        onError: function (error) {
                            if (typeof callbacks.beforeOnError === "function") {
                                const proceed = callbacks.beforeOnError(error);
                                if (proceed === false) {
                                    callbacks.afterAll(null, false);
                                    return;
                                }
                            }

                            updateStatus(LIGHTBOX_ERROR);

                            if (typeof callbacks.afterOnError === "function") {
                                callbacks.afterOnError(error);
                            }

                            if (typeof callbacks.afterAll === "function") {
                                callbacks.afterAll(null, false);
                            }
                        },

                        onCancelled: function () {
                            if (
                                typeof callbacks.beforeOnCancelled ===
                                "function"
                            ) {
                                const proceed = callbacks.beforeOnCancelled();
                                if (proceed === false) {
                                    callbacks.afterAll(null, false);
                                    return;
                                }
                            }

                            if (
                                typeof callbacks.afterOnCancelled === "function"
                            ) {
                                callbacks.afterOnCancelled();
                            }

                            if (typeof callbacks.afterAll === "function") {
                                callbacks.afterAll(null, false);
                            }
                        },

                        onDeclined: function (response) {
                            if (
                                typeof callbacks.beforeOnDeclined === "function"
                            ) {
                                const proceed =
                                    callbacks.beforeOnDeclined(response);
                                if (proceed === false) {
                                    callbacks.afterAll(response, false);
                                    return;
                                }
                            }

                            updateStatus(TRY_AGAIN);

                            if (
                                typeof callbacks.afterOnDeclined === "function"
                            ) {
                                callbacks.afterOnDeclined(response);
                            }

                            if (typeof callbacks.afterAll === "function") {
                                callbacks.afterAll(response, false);
                            }
                        },

                        onApproval: function (response) {
                            if (
                                typeof callbacks.beforeOnApproval === "function"
                            ) {
                                const proceed =
                                    callbacks.beforeOnApproval(response);
                                if (proceed === false) {
                                    callbacks.afterAll(response, false);
                                    return;
                                }
                            }

                            let success = false;
                            if (
                                typeof response === "object" &&
                                response !== null
                            ) {
                                //If the AVS code is not M, X, Y or space - or the Error Codes 5000, 5001, 5021, 5022, 5100
                                const avsCode =
                                    typeof response.ssl_avs_response ===
                                    "string"
                                        ? response.ssl_avs_response
                                              .trim()
                                              .toUpperCase()
                                        : null;
                                const errorCode =
                                    typeof response.errorCode !== "undefined"
                                        ? String(response.errorCode).trim()
                                        : null;
                                if (
                                    AVS_CODES.indexOf(avsCode) === -1 ||
                                    ERROR_CODES.indexOf(errorCode) > -1
                                ) {
                                    updateStatus(TRY_AGAIN);
                                } else {
                                    updateStatus("");
                                    success = true;
                                }
                            }

                            if (
                                typeof callbacks.afterOnApproval === "function"
                            ) {
                                callbacks.afterOnApproval(response, success);
                            }

                            if (typeof callbacks.afterAll === "function") {
                                callbacks.afterAll(response, success);
                            }
                        },
                    },
                    options
                );
            } else if (data && data.location) {
                window.location.href = data.location;
            } else {
                updateStatus(LIGHTBOX_ERROR);
            }
        },
        error: function(error) {
            console.log('errors', error);
        }
    });
}

function enablePaymentSubmit () {
    const $btnSubmit = $('button[name="dwfrm_accountpayment_submitPayment"]');
    if ($('input[name$="_accountpayment_paymentMethods_selectedPaymentMethodID"]:checked').val() === 'CREDIT_CARD') {
        const $creditCardList = $('input[name$="_accountpayment_paymentMethods_creditCardList"]');

        if ($creditCardList.length === 0 || !$creditCardList.val()) {
            $btnSubmit.attr('disabled', 'disabled');
        } else {
            $btnSubmit.removeAttr('disabled');
        }
    } else {
        $btnSubmit.removeAttr('disabled');
    }
}

function handleAutopayListUpdate (data) {
    if (typeof data !== 'string' || data.length === 0) {
        return;
    }

    data = data.split('~autopayform~');
    if (data.length !== 2) {
        return;
    }
    $('.payment-row:not(.autopay-payment-row) [data-method="CREDIT_CARD"]').html(data[0]);
    $('.autopay-payment-row [data-method="CREDIT_CARD"]').html(data[1]);
}

function initializeEvents () {
    $('.payment-method.credit-card').on('click', '.lightbox-checkout', function (e) {
        const $this = $(e.currentTarget);
        $this.attr('disabled', 'disabled');
        openLightbox(Urls.lightboxCheckout, {
            afterOnApproval : function (response, success) {
                if (success) {
                    const postData = {
                        lightboxResponse: JSON.stringify(response)
                    };
                    $.post(Urls.lightboxCheckoutAddCard, postData, function (data) {
                        $('.billing-credit-card-list').html(data);
                    });
                }
            },
            afterAll : function () {
                $this.removeAttr('disabled');
            }
        });
        return false;
    });

    // Auto-payment form initial payment
    $('.autopayment-wrapper').on('click', '.lightbox-account-payment', function (e) {
        const $this = $(e.currentTarget);
        $this.attr('disabled', 'disabled');
        openLightbox(Urls.lightboxAccountPayment, {
            afterOnApproval : function (response, success) {
                if (success) {
                    const postData = {
                        lightboxResponse: JSON.stringify(response),
                        autopay: 'Y'
                    };
                    $.post(Urls.lightboxAccountPaymentAdd, postData, function (data) {
                        handleAutopayListUpdate(data);
                    });
                }
            },
            afterAll : function () {
                $this.removeAttr('disabled');
            }
        });

        return false;
    });

    // Auto-payment form card to enroll
    $('.autopayment-wrapper').on('click', '.lightbox-account-auto-payment', function (e) {
        const $this = $(e.currentTarget);
        $this.attr('disabled', 'disabled');

        openLightbox(Urls.lightboxAccountPayment, {
            afterOnApproval : function (response, success) {
                if (success) {
                    var onlyAuto = $('.payment-row:not(.autopay-payment-row) [data-method="CREDIT_CARD"]').length === 0;
                    const postData = {
                        lightboxResponse: JSON.stringify(response),
                        autopay: onlyAuto ? 'only' : 'Y'
                    };
                    $.post(Urls.lightboxAccountPaymentAdd, postData, function (data) {
                        handleAutopayListUpdate(data);
                    });
                }
            },
            afterAll : function () {
                $this.removeAttr('disabled');
            }
        });

        return false;
    });

    $('.make-payment').on('click', '.lightbox-account-payment', function (e) {
        const $this = $(e.currentTarget);
        $this.attr('disabled', 'disabled');

        openLightbox(Urls.lightboxAccountPayment, {
            afterOnApproval : function (response, success) {
                if (success) {
                    const postData = {
                        lightboxResponse: JSON.stringify(response)
                    };
                    $.post(Urls.lightboxAccountPaymentAdd, postData, function (data) {
                        $('[data-method="CREDIT_CARD"]').html(data);
                        enablePaymentSubmit();
                    });
                }
            },
            afterAll : function () {
                $this.removeAttr('disabled');
            }
        });

        return false;
    });

    $('.lightbox-manage-cards').on('click', function (e) {
        const $this = $(e.currentTarget);
        $this.attr('disabled', 'disabled');

        openLightbox(Urls.lightboxAccountPayment, {
            afterOnApproval : function (response, success) {
                if (success) {
                    const postData = {
                        lightboxResponse: JSON.stringify(response)
                    };
                    $.post(Urls.lightboxAccountAddCard, postData, function (data) {
                        if (String(data).trim().toLowerCase() !== 'success') {
                            updateStatus(LIGHTBOX_ERROR);
                            return;
                        }
                        location.reload();
                    });
                }
            },
            afterAll : function () {
                $this.removeAttr('disabled');
            }
        });

        return false;
    });

    $('.lightbox-downpayment').on('click', function (e) {
        const $this = $(e.currentTarget);
        $this.attr('disabled', 'disabled');

        var postData = {
            ssl_first_name : $('input[name="firstName"]').val(),
            ssl_last_name : $('input[name$="_lastName"]').val(),
            ssl_amount : $('input[name="paymentAmount"]').val(),
            ssl_orderNumber : $('input[id="orderNumber"]').val()
        };

        openLightbox(Urls.lightboxDownPaymentAuth, {
            afterOnApproval : function (response, success) {
                if (success) {
                    const postData = {
                        lightboxResponse: JSON.stringify(response)
                    };
                    $.post(Urls.lightboxDownPayment, postData, function (data) {
                        $('#downPaymentCreditCardList input:checked').prop('checked', false);
                        if (dpCardAdded) {
                            $('#downPaymentCreditCardList .form-row:last-child').remove();
                        }
                        $('#downPaymentCreditCardList').append(data);
                        dpCardAdded = true;
                        $('.downpayment-save-card').removeClass('hide');
                        $('div.downpayment button:not(.lightbox-downpayment)').removeAttr("disabled");
                    });
                }
            },
            afterAll : function () {
                $this.removeAttr('disabled');
            }
        }, postData);

        return false;
    });

    $('input[name$="_accountpayment_paymentMethods_selectedPaymentMethodID"]').on('click', () => {
        enablePaymentSubmit();
    });

    if ($('#downPaymentCreditCardList').length > 0) {
        if ($('#downPaymentCreditCardList input').length === 0) {
            $('div.downpayment button:not(.lightbox-downpayment)').attr('disabled', 'disabled');
        }
    }

    enablePaymentSubmit();
}

exports.init = function init () {
    initializeEvents();

    // hack to get brand primary background color
    const $lightboxStatus = $('.lightbox-status');
    if ($lightboxStatus.length > 0) {
        $lightboxStatus.append('<div class="brand-primary-bg hidden"></div>')
        const $brandPrimary = $lightboxStatus.find('.brand-primary-bg');
        brandPrimary = $brandPrimary.css('background-color');
        $brandPrimary.remove();
    }
};