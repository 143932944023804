const dialog = require('../../dialog');

const productUtil = {

    /**
     * @function
     * @description Helper to show quantity limit dialog
     */
    showLimitErrorDialog() {
        dialog.create({
            target: $('#QuantityPurchaseDialog'),
            options: {
                width: '700',
            },
        });
        dialog.open({
            html: Resources.QUANTITY_RESTRICTION_MSG,
            options: {
                title: Resources.QUANTITY_RESTRICTION,
                buttons: [{
                    text: Resources.OK,
                    class: 'button primary',
                    click() {
                        $(this).dialog('close');
                    },
                }],
            },
        });
    },

    /**
     * @description Make sure the selected quantities match pack count for Pick 'n Choose products
     * and display a dialog if quantities don't match
     * @returns {Boolean}
     */
    validatePickNChoose() {
        const pnc = $('.pnc');
        if (pnc.length) {
            const packCount = parseInt(pnc.data('packcount'), 10);
            let totalQty = 0;
            pnc.find('.pnc-qty').each((index, element) => {
                totalQty += parseInt($(element).val(), 10);
            });
            // Display an error dialog if pack count doesn't equal summed quantities
            if (totalQty !== packCount) {
                dialog.create({
                    target: $('#PickNChooseDialog'),
                    options: {
                        width: '700',
                    },
                });
                dialog.open({
                    html: Resources.PICK_N_CHOOSE_ERROR,
                    options: {
                        title: Resources.PRODUCT_ALERT,
                        buttons: [{
                            text: Resources.OK,
                            class: 'button primary',
                            click() {
                                $(this).dialog('close');
                            },
                        }],
                    },
                });
                return false;
            }
        }
        return true;
    },

    checkForOptionProductFee(formID) {
        if ($('.product-options select').length) {
            const noCustomization = $('.product-options select option:eq(0)').val();
            const customization = $('.product-options select option:eq(1)').val();
            let allFieldsLength = 0;
            let customerWantsPersonalization;
            if ($('#personalized-check').length) {
                customerWantsPersonalization = $('#personalized-check').is(':checked');
            } else {
                customerWantsPersonalization = false;
            }

            $(`#${formID} .personalization-options, #${formID} .ps-personalization-options`).find('input, select').each((index, element) => {
                if ($(element).prop('type') === 'select-one') {
                    allFieldsLength += $(element).prop('selectedIndex');
                } else if ($(element).prop('type') === 'text') {
                    allFieldsLength += $(element).val().length;
                }
            });

            if (allFieldsLength > 0 && customerWantsPersonalization) {
                $(`.product-options select option[value=${customization}]`).prop('selected', true);
            } else if (allFieldsLength > 0 && !customerWantsPersonalization) { // if there are values and the checkbox is not checked, set to no custom and clear fields
                $(`.product-options select option[value=${noCustomization}]`).prop('selected', true);
                $(`#${formID} .personalization-options, #${formID} .ps-personalization-options`).find('input, select').each((index, element) => {
                    $(element).val('');
                });
            } else {
                $(`.product-options select option[value=${noCustomization}]`).prop('selected', true);
            }
        }
    },
};

module.exports = productUtil;
